@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Work Sans", sans-serif !important;
  background: #fff;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

::-webkit-scrollbar {
  width: 4px;
}

.test_table::-webkit-scrollbar,
.user_table::-webkit-scrollbar {
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0096ff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0096ff;
}

/*=== site header ===*/
header {
  padding: 12px 0;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.user_info h4 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/*=== sidebar ===*/
.sidebar {
  position: fixed;
  width: 16.66666667%;
  min-height: 100vh;
  background: #101a36;
  padding: 96px 35px 40px;
}

.sidebar ul li a:after {
  position: absolute;
  content: "";
  width: 0px;
  height: 2px;
  background: #00d7ff;
  bottom: 0;
  left: 1px;
  margin: auto;
  transition: 0.5s;
  bottom: -8px;
}

.dashboard {
  top: 4px;
}

.sidebar ul li.active a:after,
.sidebar ul li:hover a:after {
  width: 15px;
  transition: 0.5s;
}

.sidebar ul li a {
  font-size: 16px;
  position: relative;
  font-style: normal;
  padding: 0;
  font-weight: 400;
  line-height: 24px;
  color: #72ffff !important;
}

.sidebar ul.nav {
  gap: 48px;
}

/*=== body page ===*/
.page_wrapper {
  padding: 96px 40px 40px 0;
}

.graph_title h3 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.btn.btn-primary {
  border-radius: 100px;
  outline: none !important;
  box-shadow: none !important;
  border: none;
  height: 40px;
  padding: 8px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  background: #0096ff;
  line-height: 24px;
  white-space: nowrap;
}

.btn.btn-outline-primary {
  border-radius: 100px;
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #0096ff;
  height: 40px;
  padding: 8px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  background: transparent;
  line-height: 24px;
  white-space: nowrap;
  color: #0096ff;
}

.btn.btn-outline-primary:hover {
  border-radius: 100px;
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #0096ff;
  height: 40px;
  padding: 8px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  background: #0096ff;
  line-height: 24px;
  white-space: nowrap;
  color: #fff;
}

.btn.btn-primary.bg-white {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) !important;
  font-weight: 500;
  -webkit-appearance: none;
  color: #101a36;
}

.graph_card_outer {
  border-radius: 40px;
  background: #fff;
  height: 100%;
  box-shadow: 0px 4px 12px 0px rgba(78, 78, 78, 0.25);
  padding: 24px 24px 32px 36px;
  position: relative;
  overflow: hidden;
}

.graph_body {
  min-height: 300px;
}

span.graph_indicator {
  border-radius: 8px;
  /* background: #00D7FF; */
  height: 8px;
  width: 24px;
  display: inline-block;
}

.line-through-text {
  text-decoration: line-through;
  /* background: gray; */
}

.graph_idicate_outer li {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.graph_indicator.persian_blue {
  background-color: #5800ff;
}

.graph_button select {
  border: none;
  background: transparent;
  color: #101a36;
  box-shadow: none !important;
  outline: none !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.dropdown ul li a,
.graph_button select option {
  color: #101a36;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.dropdown ul {
  border: none;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 0;
  overflow: hidden;
}

.test_dropdown {
  position: relative;
}

.dropdown-toggle::after,
.test_dropdown::after {
  border: 0;
  background-image: url("assets/images/toggle.png");
  width: 23px;
  height: 23px;
  margin-left: 10px;
}

.pagination-select select {
  margin-right: 15px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #101a36;
  border: 1px solid #101a36 !important;
  height: 51px;
  width: auto;
  padding: 0 8px;
  border-radius: 9px;
}

.test_dropdown::after {
  position: absolute;
  content: "";
  bottom: 0;
  top: 0;
  right: 15px;
  margin: auto;
}

.graph_button h6 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.custom-select select option {
  color: #101a36;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

/*=== scentaware ===*/
.page_section {
  padding-top: 96px;
  padding-bottom: 40px;
}

.view_test_button .btn.btn-primary {
  height: 56px;
  min-width: 198px;
}

li.seprator {
  opacity: 0.3;
  background: #5800ff;
  width: 2px !important;
  height: 32px;
  padding: 0 !important;
}

.text_log_card {
  width: 100%;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(78, 78, 78, 0.25);
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text_log_card ul {
  gap: 0px;
  justify-content: space-between;
  width: 100%;
}

.text_log_card ul li {
  flex: 0 0 auto;
  width: 33.33%;
  text-align: left;
  align-items: center;
  padding: 0 35px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  justify-content: space-between;
  display: flex;
}

.text_log_card ul li b {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.page_title {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.scent_aware .col {
  width: auto;
  flex: 0 0 auto;
}

.scent_aware_col {
  width: 69.6% !important;
}

/*=== ScentAware graph ===*/

.graph_body.inner_graph {
  min-height: 150px;
}

.pie_list ul li span {
  display: inline-block;
  width: 24px;
  height: 24px;
  flex: 0 0 auto;
  background: #004ab7;
  border-radius: 50px;
}

.pie_list ul li {
  gap: 24px;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  align-items: center;
}

.pie_list ul li:not(:last-child) {
  margin-bottom: 24px;
}

.pie_list ul li span.bg_blue {
  background: #0e84f2;
}

.pie_list ul li span.bg_light_b {
  background: #99e7fd;
}

/*=== test log ===*/
.view_test_button .dropdown .btn.btn-primary {
  min-width: 166px;
}

.test_table tr td {
  word-break: break-all;
  width: 10%;
  min-width: 160px;
}

button.search_button {
  width: 56px;
  height: 56px;
  border-radius: 16px !important;
  background: #0096ff;
  border: none;
}

.input-group {
  border-radius: 16px;
  background: #f2f2f2;
  min-width: 100%;
  height: 55px;
  align-items: center;
}

.input-group .react-datepicker-wrapper {
  padding: 0 12px;
  width: 100%;
}

.input-group input[type="date"] {
  padding: 0 12px;
  width: 100%;
}

.add_participend {
  border-radius: 12px;
  overflow: hidden;
  padding-bottom: 0 !important;
}

.input-group input,
.input-group input::placeholder,
.input-group select,
.input-group textarea {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  outline: none !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #838383;
}

.loader {
  width: 20px;
  height: 20px;
  border: 3px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.test_table thead tr th,
.test_table tbody tr td {
  color: #101a36;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  vertical-align: middle;
}

.test_table thead tr th {
  padding: 16px;
  border-bottom: 1px solid #101a36;
}

.test_table tbody tr td {
  border-bottom: 1px solid #101a36;
  padding: 16px;
}

.test_table thead tr th {
  white-space: nowrap;
}

.test_table tbody tr td {
  font-weight: 500;
}

.test_table {
  overflow: auto;
}

.pagination p {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: #101a36;
}

.pagination p b {
  font-weight: 600;
}

span.pagination_count {
  border-radius: 16px;
  border: 2px solid var(--Cool-Blue, #0096ff);
  width: 51px;
  height: 51px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #101a36;
  transition: 0.5s;
}

span.pagination_count.active,
span.pagination_count:hover {
  background: #0096ff;
  color: #fff;
  transition: 0.5s;
  cursor: pointer;
}

.disabled-select:disabled {
  background-color: darkgray !important;
  border-radius: 15px;
}

/*===super clinical ===*/
.prince_ton_card h4 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #101a36;
  max-width: 65%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prince_ton_card ul li {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  color: #101a36;
}

.new_trial hr {
  background: #d9d9d9;
}

.new_trial button.btn.btn-primary {
  width: 94.505%;
  height: 53px;
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  box-shadow: 0px 8px 8px 0px rgba(78, 78, 78, 0.25) !important;
}

.prince_ton_card.prince_active {
  background: #d1f3ff;
  border-radius: 4px;
}

.prince_ton_card.prince_active h4 {
  font-weight: bold;
}

.prince_ton_card.prince_active ul li {
  font-weight: 400;
}

.small_acutiy_card h3 {
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.96px;
  color: #101a36;
}

.small_acutiy_card p {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #101a36;
}

.small_acutiy_card a {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #101a36;
}

.moderators h3 {
  color: #101a36;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.maderators_icon {
  width: 40px;
  height: 40px;
  background: #9747ff;
  color: #fff;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

.maderators_info h3 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 4px;
}

.maderators_info p {
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: #101a36;
}

.test_table tbody tr td a {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #0096ff;
  width: 100%;
  white-space: nowrap;
  text-align: right;
  display: block;
}

/*=== active user ===*/
.search_icon_inner {
  border-radius: 8px;
  border: 1px solid #101a36;
  background: #fff;
  height: 48px;
  padding: 0;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.search_icon {
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cross_icon {
  position: absolute;
  right: 8px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="search" i]::-webkit-search-cancel-button {
  display: none;
}

.search_icon_inner input {
  border: none;
  padding: 0 0 0 36px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #7e7e7e;
  height: 100%;
  outline: none;
  box-shadow: none !important;
}

ul.user_list li {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 20px;
  color: #101a36;
}

ul.user_list li.active {
  background: #a1e7ff;
  font-weight: 700;
}

ul.user_list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

ul.user_list li button.accordion-button {
  padding: 8px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #101a36;
  line-height: 24px;
  outline: none;
  box-shadow: none;
  background-color: transparent !important;
  width: 100%;
}

ul.user_list>li:last-child {
  padding: 0;
}

ul.user_list li .accordion-body ul li {
  padding: 8px 16px;
}

.accordion-button::after {
  /* background-image: url(../src/assets/images/tempwareImages/accordion_arrow.png); */
  background-size: 24px 24px;
  background-position: center;
  width: 24px;
  height: 24px;
}

.accordion-button:not(.collapsed)::after {
  /* background-image: url(../src/assets/images/tempwareImages/accordion_arrow.png); */
}

.user_list ul li {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #101a36;
  padding: 4px 8px;
  word-break: break-all;
  gap: 12px;
}

.ant-list-split .ant-list-item {
  border-bottom: 0px solid #f0f0f0 !important;
}

.user_name {
  flex: 0 0 auto;
  width: 32px;
  border-radius: 100%;
  height: 32px;
  background: #0096ff;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  text-transform: uppercase;
  text-align: center;
}

.user_list ul {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.user_list ul li.active {
  background: #0096ff;
  color: #fff;
}

.small_acutiy_card.hanna h3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #101a36;
}

.small_acutiy_card.hanna h3 .btn {
  padding: 8px 24px;
}

.user_address ul li {
  color: #101a36;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.user_address ul {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.user_address {
  display: flex;
  gap: 78px;
}

.small_acutiy_card.hanna table tr td {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #101a36;
  width: 10%;
  padding: 8px 16px;
  white-space: nowrap;
  border-bottom: 1px solid #b9b9ba;
}

.small_acutiy_card.hanna table tr td:nth-child(2) {
  text-align: center;
  font-weight: 400;
}

.small_acutiy_card.hanna table tr td:last-child {
  text-align: right;
}

.user_table {
  overflow: auto;
  width: 100%;
}

.ant-steps-item-title::after {
  display: none !important;
}

/*== form preview ==*/
.review_card {
  background: #fff;
  border: 1px solid #cdcdcd;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 #d9d9d9;
}

.review_card h5 {
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 25px !important;
  margin: 5px 0 5px !important;
}

.edit_iconclinic {
  position: relative;
  top: 0px;
}

.edit_iconclinic span.edit.active.users_status {
  border-radius: 100%;
  background: transparent;
  color: #101a36;
  padding: 0;
}

.review_card label {
  font-size: 14px;
  font-weight: 600;
  color: #868686;
}

.moderator_table tr th {
  font-size: 13px;
  font-weight: 700;
  color: #3a3a3a;
  width: 10%;
  white-space: nowrap;
}

.moderator_table tr td {
  font-size: 12px;
  font-weight: 400;
  color: #3a3a3a;
}

.moderator_table tr:last-child td {
  border: none;
}

.review_detail {
  min-height: 45px;
  max-height: 90px;
  overflow: auto;
  background: #f2f2f2;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 11px 15px;
  margin-top: 5px;
}

.review_detail h6 {
  font-size: 14px;
  margin: 0;
  color: #000;
  font-weight: 400;
}

label.ant-form-item-required.form_label {
  font-weight: 500;
  color: #000000d9;
  font-style: normal;
  line-height: 19px;
  font-size: 18px;
}

label.ant-form-item-required.form_label span {
  color: red;
}

.edit_iconclinic {
  position: relative;
  top: 0px;
}

/*=== checkbox ===*/
form.ant-form.ant-form-horizontal .checkbox-group .ant-row {
  display: flex !important;
  flex-direction: row;
}

.fwidth-search-input .input_redio input {
  width: 20px;
  height: 20px;
  background: transparent !important;
  box-shadow: none !important;
  margin-left: 20px;
}

.ant-form-horizontal .checkbox-group .ant-form-item-control {
  flex: 0 0 auto;
  margin-left: 10px;
}

/*=== add config modal UI====*/
.ant-modal.mapConfigModal {
  width: 100% !important;
  max-width: 730px !important;
  border-radius: 24px;
  background: #fff;
  overflow: hidden;
  padding: 0 !important;
}

.mapConfigModal h5 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #dee3e5;
}

.mapConfigModal p {
  color: #c0c8cc;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
}

.ModaldeviceInfo {
  color: #dee3e5;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mapConfigModal label {
  color: #dee3e5;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
}

.mapConfigModal input.form-control {
  height: 60px;
  border-radius: 4px;
  border: 2px solid #70787c;
  background-color: transparent;
  outline: none !important;
  box-shadow: none !important;
  padding: 18px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
  color: #c0c8cc;
}

.mapConfigModal .form-select {
  height: 60px;
  border-radius: 8px;
  border: 2px solid #70787c;
  background-color: transparent;
  outline: none !important;
  box-shadow: none !important;
  padding: 18px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
  color: #c0c8cc;
}

.mapConfigModal span.formToo {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #dee3e5;
}

.mapConfigModal input.form-control::placeholder {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #c0c8cc;
}

.configButton .btn,
.configButton .btn:hover,
.configButton .btn:active,
.configButton .btn:focus {
  padding: 10.5px 24px !important;
  border-radius: 100px !important;
  background: rgba(222, 227, 229, 0.12) !important;
  border: 0px solid #031a40;
  height: auto;
  min-height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: rgb(222 227 229 / 38%) !important;
  outline: none !important;
  box-shadow: none !important;
}

.configButton .btn.btn-outline-primary,
.configButton .btn.btn-outline-primary:hover,
.configButton .btn.btn-outline-primary:active,
.configButton .btn.btn-outline-primary:focus {
  background: transparent !important;
  color: #8ecff2 !important;
  opacity: 1 !important;
}

.mapConfigModal .form-check-input,
.mapConfigModal .form-check-input:hover,
.mapConfigModal .form-check-input:focus,
.mapConfigModal .form-check-input:acitve {
  width: 24px;
  height: 24px;
  border: 2px solid #031a40;
  outline: none;
  box-shadow: none;
}

.mapConfigModal button.ant-modal-close,
.listGraphView button.ant-modal-close {
  display: none !important;
}

.ant-modal.listGraphView {
  width: 100% !important;
  max-width: 1156px !important;
  margin: auto;
  border-radius: 24px !important;
  background: #fff;
  box-shadow: 0px 48px 64px 0px rgba(3, 26, 64, 0.5) !important;
  padding: 0;
  overflow: hidden !important;
}

.listGraphView .graphTable {
  max-height: 455px;
  overflow: auto;
}

.listGraphView .ant-modal-body {
  padding: 0;
}

.graphTableheadTitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #101a36;
}

.modalFilterTitle span {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #c0c8cc;
  letter-spacing: 0.1px;
}

/*=== mobile view ===*/

@media (max-width: 1460px) {
  .scent_aware_col {
    width: 66.4% !important;
  }

  .btn.btn-primary {
    font-size: 13px;
    padding: 8px 15px;
  }
}

@media (max-width: 1370px) {
  .graph_idicate_outer li {
    font-size: 13px;
  }

  .btn.btn-primary {
    padding: 8px 15px;
    font-size: 12px;
  }

  .sidebar ul li a {
    font-size: 12px;
  }

  .sidebar ul.nav {
    gap: 28px;
  }

  .scent_aware_col {
    width: 66% !important;
  }
}

@media (max-width: 1300px) {
  .scent_aware .col {
    width: 100%;
    flex: 0 0 auto;
    justify-content: center !important;
  }

  .sidebar {
    padding: 96px 30px 40px;
  }

  .scent_aware_col {
    width: 100% !important;
  }

  .scent_aware {
    gap: 24px;
  }

  .pie_list ul li {
    font-size: 13px;
  }

  .view_test_button .btn.btn-primary {
    height: 56px;
    min-width: 167px;
  }
}

@media (max-width: 1200px) {
  .graph_idicate_outer li {
    font-size: 13px;
  }

  .page_wrapper {
    padding: 74px 0px 40px 0;
  }

  .graph_title h3 {
    font-size: 15px;
  }

  .sidebar {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    width: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0;
    overflow: hidden;
    transition: 0.5s;
  }

  .pie_list ul li:not(:last-child) {
    margin-bottom: 10px;
  }

  .pie_list ul li {
    font-size: 11px;
  }

  .sidebar.show {
    width: 100%;
    padding: 20px;
    transition: 0.5s;
    height: auto;
  }

  .sidebar ul li a {
    justify-content: center;
    align-items: center;
  }

  .sidebar ul.nav {
    gap: 28px;
  }

  .view_test_button .btn.btn-primary {
    min-width: auto;
    flex-basis: 1;
    flex: auto;
  }

  .page_title {
    font-size: 21px;
  }

  .page_section {
    padding-top: 74px;
    padding-bottom: 40px;
  }
}

@media (max-width: 1100px) {
  .page_title {
    font-size: 18px;
  }

  .page_title svg {
    width: 30px;
  }
}

@media (max-width: 990px) {
  .text_log_card ul li {
    padding: 0 20px;
    font-size: 12px;
  }

  .page_title {
    font-size: 21px;
  }

  .view_test_button .btn.btn-primary {
    height: 50px;
  }

  .test_table thead tr th,
  .test_table tbody tr td {
    font-size: 11px;
  }

  .test_table thead tr th {
    padding: 10px;
  }

  span.pagination_count {
    width: 41px;
    height: 41px;
    font-size: 14px;
  }

  button.search_button {
    width: 41px;
    height: 41px;
  }

  .user_list {
    max-height: 240px;
    overflow: auto;
  }
}

@media (max-width: 767px) {
  .graph_card_outer {
    padding: 25px 15px;
  }

  .user_list {
    max-height: 140px;
    overflow: auto;
  }

  .btn.btn-primary {
    padding: 8px 15px;
    white-space: nowrap;
    font-size: 12px;
  }

  .graph_idicate_outer li {
    font-size: 11px;
    white-space: nowrap;
  }

  .graph_idicate_outer li svg {
    width: 30px;
  }

  .graph_button button.dropdown-toggle {
    font-size: 11px;
  }

  .graph_title h3 {
    font-size: 14px;
  }

  .graph_button h6 {
    font-size: 11px;
  }

  .user_info h4 {
    font-size: 11px;
  }

  .brand img {
    width: 100px;
  }

  .text_log_card ul {
    flex-direction: column;
  }

  .text_log_card {
    height: auto;
    padding: 15px;
  }

  .text_log_card ul li {
    padding: 0 0px;
    font-size: 12px;
    width: 100%;
  }

  li.seprator {
    width: 100% !important;
    height: 2px;
    margin: 10px 0;
  }

  .small_acutiy_card.hanna table tr td {
    padding: 8px 5px;
  }

  .small_acutiy_card a {
    padding: 0;
  }

  .view_test_button .btn.btn-primary {
    height: auto;
  }

  .view_test_button .btn.btn-primary {
    min-width: auto;
    flex-basis: 1;
    flex: auto;
  }

  /* .pagination {
        flex-direction: column;
        gap: 30px;
    } */

  span.pagination_count {
    width: 24px;
    height: 24px;
    font-size: 11px;
    border-radius: 8px;
  }

  .pagination p {
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    color: #101a36;
    white-space: nowrap;
  }

  .previour svg,
  .next svg {
    width: 18px;
    height: 18px;
  }
}

/*=== login page ===*/
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.menubar {
  /* background-color: #153f67; 1F4180*/
  background-color: #1f4180;
  padding: 13px 62px;
}

.menubar-2 {
  background-color: transparent;
  padding: 13px 62px 0px 62px;
}

.input-icons {
  width: 255px;
  height: 36px;
  background-color: #536d87;
  border: 0px;
  border-radius: 19px;
  color: #7a8a9a;
  display: flex;
  align-items: center;
  padding: 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  outline: none;
}

th.ant-table-cell {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
}

.input-icons input {
  background: transparent;
  border: 0px;
  outline: none;
}

input.input-field {
  padding-left: 12px;
  outline: none;
}

/* .menubar input {
 
} */

.hello {
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  /* line-height: 18px; */
}

.name-icon {
  width: 40px;
  height: 40px;
  background: #0e84f2;
  border-radius: 28px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  padding: 10px;
}

.spacing {
  width: 95%;
  margin: 0 auto !important;
}

/* .menubar .sbar {
  width: 252px;
  height: 34px;
  background-color: #536D87;
  border: 0px solid;
  border-radius: 19px;
  color: #7A8A9A;
  display: flex;
  align-items: center;
  padding: 15px;

} */

.mendiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-digit-section {
  display: flex;
  justify-content: space-evenly;
}

.card {
  border: 0px solid;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(78, 78, 78, 0.2);
  border-radius: 8px;
  padding: 15px 27px;
}

.Donut-section {
  margin-top: 20px;
}

.all-cards {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.card h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  color: #4e4e4e;
}

.card p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

ul {
  padding: 0;
  list-style: none;
  font-size: 16px;
  font-weight: 300;
}

.content h4 {
  font-size: 16px;
  color: #4e4e4e;
  line-height: 35px;
  font-weight: 100 !important;
  margin: 0;
}

.number h5 {
  font-size: 32px;
  color: #4e4e4e;
  font-weight: 300;
  margin: 0;
  padding: 0px;
  text-decoration: auto;
  width: 100%;
  padding-bottom: 10px;
}

.card2 {
  border: 0px solid;
  width: 400px;
  height: 270px;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(78, 78, 78, 0.2);
  border-radius: 8px;
  padding: 20px;
}

.card2 h2 {
  font-size: 22px;
  font-weight: 700;
  color: #4e4e4e;
}

.card2 p {
  color: #4e4e4e;
  font-size: 12px;
}

.card2 ul {
  list-style: none;
  color: #4e4e4e;
  line-height: 25px;
  padding: 0px;
}

.card2 span {
  font-size: 18px;
  font-weight: 700;
  padding: 0px 22px;
  color: #4e4e4e;
}

.view {
  display: flex;
  align-items: center;
  justify-content: end;
}

.view img {
  padding: 17px;
  cursor: pointer;
}

.view span {
  font-size: 16px;
  font-weight: 700;
  color: #4e4e4e;
}

.Leftside-col {
  display: flex;
  padding: 35px 30px;
}

.Topheading-col {
  display: flex;
  padding: 50px 38px 0px 38px;
}

.Topheading-col img {
  width: 25px;
  height: 22px;
  cursor: pointer;
}

.Topheading-col h2 {
  color: #4e4e4e;
  padding-left: 25px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin: 0;
}

.Maincontainer {
  padding-bottom: 30px;
  padding-top: 25px;
}

.Leftside-col img {
  width: 25px;
  height: 22px;
  cursor: pointer;
}

.center-col img {
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.center-col {
  display: flex;
  justify-content: flex-end;
}

.Leftside-col h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #4e4e4e;
  padding-left: 25px;
  margin: 0px;
}

.center-col h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-left: 23px;
}

.Download-col {
  display: flex;
  padding: 0px 55px;
}

.Rightside-col button {
  cursor: pointer;
  width: 107px;
  height: 39px;
  border-radius: 20px;
  border: 0px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgb(78 78 78 / 20%);
  color: #797979;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.Rightside-col img {
  padding: 5px 10px;
  cursor: pointer;
}

.Rightside-col {
  display: flex;
  justify-content: center;
}

.card-digit-section {
  display: flex;
}

.Trace-position-changed {
  display: flex;
  justify-content: right;
  flex: auto;
  padding: 20px;
}

.Rightside-col.home {
  display: flex;
  justify-content: left;
}

.Audioaware {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.card-digit-Traceaware-section {
  background: #ffffff;
  box-shadow: 0px 8px 16px rgb(78 78 78 / 20%);
  border-radius: 8px;
}

.Container {
  margin: 0 auto;
  width: 92%;
}

.firstcard {
  margin-right: 27px;
}

.login {
  background-image: url("assets/images/Dashboard_login_hero.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 93vh;
}

form#basic {
  background-color: #013259;
  padding: 15px;
  width: fit-content;
  width: 382px;
  padding: 40px;
  display: block;
  margin-top: 150px;
  border-radius: 8px;
}

input#basic_email {
  width: 292px;
}

button.ant-btn.ant-btn-primary {
  background: #26649d;
  border-radius: 8px;
  width: 50%;
  height: 40px;
  font-weight: bolder;
}

button.ant-btn.ant-btn-default.nextStep-page {
  width: 50%;
  height: 40px;
  border-radius: 5px;
}

form#basic h5 {
  display: flex;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
  text-decoration: underline;
  justify-content: right;
  padding-right: 22px;
  padding-bottom: 30px;
  color: #4e4e4e;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

form#basic h4 {
  font-weight: 500 !important;
  font-size: 20px;
  line-height: 28px;
  color: #4e4e4e;
  padding-bottom: 19px;
}

.ant-form-vertical .ant-form-item .ant-form-item-control {
  margin: 0px;
}

.Main-field {
  display: flex;
  justify-content: end;
  width: 92%;
}

label.ant-form-item-required {
  font-weight: 500;
  color: #4e4e4e;
  font-style: normal;
  line-height: 19px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.input-field h4 {
  font-size: 24px;
  margin: 0px;
}

.Main-field input,
span.ant-input-affix-wrapper.ant-input-password {
  border-color: #26649d !important;
  height: 40px;
  border-radius: 5px !important;
  outline: none;
}

form#basic h5 {
  font-size: "16px";
  color: #33c9fc;
  font-weight: normal !important;
}

.ant-checkbox-wrapper-in-form-item span {
  font-size: 16px;
  color: #4e4e4e;
}

.Dashboard-Table h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  min-height: 75px;
  display: flex;
  align-items: center;
}

.list span {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

.list p {
  font-weight: 100 !important;
  font-size: 18px;
}

.list {
  list-style: none;
  border-bottom: 1px solid #b9b9ba;
  margin-bottom: 10px;
  width: 70%;
}

li.list.list-two,
.list-three {
  height: 75px;
  display: flex;
  align-items: center;
}

li.list.list-three {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.button-section-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 55px 0px 0px;
}

.button-section-heading button {
  background-color: #0e84f2;
  border: none;
  width: 90px;
  height: 45px;
  border-radius: 18px;
  color: #fff;
  font-weight: 800;
  font-size: 16px;
}

.steps-content .ant-row.ant-form-item-row {
  flex-direction: column;
}

.steps-wrapper .ant-form-item-label {
  display: flex;
}

label.ant-form-item-required {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

.steps-wrapper input {
  background-color: #f3f3f3 !important;
  height: 50px;
  outline: none;
}

.steps-wrapper textarea.ant-input {
  background-color: #f3f3f3 !important;
}

.steps-wrapper {
  margin-left: 100px;
  margin-top: 55px;
}

button.ant-btn.ant-btn-default.ant-btn-lg.default_btn.colored_btn {
  background-color: #26649d;
  width: 235px;
  height: 50px;
  border-radius: 12px;
  color: #fff;
  margin-top: 20px;
}

button.ant-btn.ant-btn-default.ant-btn-lg.default_btn.transparent_btn {
  width: 235px;
  height: 50px;
  color: #26649d;
  margin-top: 20px;
  border: 0px !important;
}

.steps-action {
  display: flex;
  justify-content: space-between;
}

.steps-content h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  text-decoration: dotted;
  display: flex;
  justify-content: inherit;
}

.Finish-Form h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  text-decoration: dotted;
  display: flex;
  justify-content: inherit;
  line-height: 45px;
}

.Finish-Form p {
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  color: #4e4e4e;
}

.Finish-Form span {
  font-weight: 700 !important;
}

.Finish-Form {
  margin-left: 100px;
  margin-top: 55px;
}

.ant-row.adduser-header h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  text-decoration: blink;
  display: flex;
  justify-content: flex-start;
}

.ant-row.adduser-header p {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #4e4e4e;
}

.form-input label.ant-form-item-required {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  outline: none;
}

.form-input input {
  height: 45px;
  background: #f3f3f3 !important;
  border: none;
  border-radius: 5px;
  outline: none;
}

.form-input .ant-col.ant-form-item-label {
  display: flex;
}

.border-button {
  border: 2px solid #26649d !important;
  height: 50px !important;
  width: 171px !important;
  border-radius: 6px !important;
  color: #26649d !important;
  margin-left: 10px !important;
}

.ant-col.ant-col-8.button-section-adduser {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-section-adduser button {
  margin-top: 0 !important;
}

.adduser {
  margin-left: 100px;
  margin-top: 55px;
}

.table-style .adduser-table {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.table-style .adduser-table li.list.list-three {
  width: 85%;
}

.table-style .adduser-table svg {
  width: 40px;
  height: 25px;
  color: red;
}

.table-style .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}

form#basic .colored_btn {
  width: 100%;
}

.ant-col.dashboard-table-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dashboard-table-header button {
  background: #0e84f2;
  box-shadow: 0px 4px 4px rgb(78 78 78 / 25%);
  width: 110px;
  height: 50px;
  border: none;
  color: #fff;
  font-weight: bolder;
  font-size: 15px;
  border-radius: 23px;
}

.ant-col.ant-col-16.Trace-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

form#adduser-form {
  display: flex;
}

.forget-section {
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
}

.forget-section input {
  border-color: #26649d !important;
  height: 40px;
  border-radius: 5px !important;
}

form {
  display: flex;
  justify-content: end;
  align-items: center;
}

.All-users-section {
  background: #013259;
  padding: 0px;
  border-radius: 16px;
}

.All-users-section h2 {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  padding-bottom: 14px;
  padding-left: 17px;
}

.Recently-joined {
  color: #fff;
  font-weight: 400px;
  font-size: 16px;
  padding-bottom: 16px;
}

.Clinical-trial1 {
  display: flex;
  justify-content: space-between;
}

.Clinical-trials-one {
  color: #ffff;
  font-size: 14px;
}

.Clinical-trials {
  color: #ffff;
  font-weight: 400;
  font-size: 17px;
}

.tharty-two {
  color: #fff;
  font-weight: 400;
  font-size: 12px;
}

.search-container input {
  background-color: #001f38;
  border: 0px;
  border-radius: 5px;
  width: 100%;
  height: 42px;
  padding-left: 18px;
  outline: none;
}

.search-container {
  background: #001f38;
  border-radius: 6px;
  margin-bottom: 10px;
}

.search-container-2 input {
  background-color: #001f38;
  border: 0px;
  border-radius: 12px;
  width: 450px;
  height: 56px;
  padding-left: 18px;
  outline: none;
  font-size: 16px;
}

.search-container-2 {
  background: #001f38;
  width: 450px;
  border-radius: 12px;
  margin-bottom: 10px;
}

span.anticon.anticon-search {
  padding-left: 10px;
}

.List-item {
  background: #013259;
  padding: 10px;
  border-radius: 16px;
}

.Hanna-Dias {
  display: flex;
  background-color: #0e84f2;
  margin-top: 14px;
  border-radius: 6px;
  padding-left: 6px;
  padding-top: 6px;
}

.HD {
  padding-right: 32px;
  font-weight: 300;
  color: #ffff;
}

.Hanna {
  font-weight: 600;
  color: #ffff;
}

.Roger-Lobin {
  display: flex;
  margin-top: 14px;
  border-radius: 6px;
}

.RL {
  padding-right: 18px;
  font-weight: 300;
  background-color: #0e84f2;
  border-radius: 20px;
}

.Roger {
  display: flex;
  justify-content: center;
  align-items: end;
  padding-left: 22px;
  color: #ffff;
}

.dias {
  font-size: 32px;
  font-weight: 300;
  color: #fff;
}

.user-personal-text {
  font-size: 14px;
  font-weight: 400;
}

.user-bars-text {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0px !important;
}

.User-id h5 {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
}

.trial {
  font-weight: 600;
  font-size: 14px;
  color: #d9d9d9;
}

.date {
  font-size: 10px;
  font-size: 300;
  color: #d9d9d9;
}

.active h3 {
  color: #33c9fc;
  font-weight: 500;
  font-size: 11px;
}

.active h2 {
  color: #33c9fc;
  font-weight: 600;
  font-size: 14px;
}

.progress-bar-section {
  margin-bottom: 15px;
}

span.anticon.anticon-right-circle {
  font-size: 40px;
  color: #85e0a3;
  cursor: pointer;
}

td.ant-table-cell {
  max-width: 250px;
}

.All-users-section,
.List-item {
  /* height: 80%; */
  height: 600px;
}

.ant-card-head-title {
  color: #ffffff;
}

.graph-card {
  border-radius: 20px !important;
}

.ant-card-head {
  border: 0px !important;
}

.ant-col.aware-card.ant-col-lg-24 {
  height: 50%;
}

.aware-card .graph-card {
  height: 95%;
}

.ant-row {
  margin: 0 auto !important;
}

main.ant-layout-content {
  margin-top: 40px !important;
}

.ant-menu,
.ant-layout-sider {
  /* border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important; */
  border-radius: 16px !important;
  /* border:  2px red solid; */
  background-color: #013259 !important;
}

.ant-layout-sider-trigger {
  background-color: #013259 !important;
  border-end-end-radius: 16px !important;
  border-end-start-radius: 16px !important;
  position: inherit !important;
  display: none;
}

.box-shadow {
  box-shadow: 0px 8px 16px rgba(4, 38, 70, 0.5) !important;
}

.ant-menu-item a {
  color: #ffff !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #184364 !important;
}

.aware-card .ant-card-head-title {
  color: black !important;
}

.ant-card-bordered {
  border: 0px !important;
}

.login {
  height: 100vh;
}

/* disable list scroll */
.rc-virtual-list-holder::-webkit-scrollbar {
  display: none;
}

span.anticon.anticon-left-circle {
  font-size: 40px;
  color: #85e0a3;
  cursor: pointer;
}

.left-euro {
  background: #013259;
  padding: 20px;
  border-radius: 16px;
  height: 100%;
}

.heading {
  font-size: 32px;
  font-weight: 300;
  color: #fff;
  text-align: center;
}

.left-euro h4 {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  padding-bottom: 4px;
}

.progress-bar {
  margin-top: 20px;
}

/* .Donut-chart {
  background: #013259;
  border-radius: 16px;
} */
/* .spline-area {
  background: #013259;
  border-radius: 16px;
} */

.ScentAware-chart .apexcharts-legend-text {
  width: 110px;
  padding-left: 20px !important;
}

.ScentAware-chart .Chart {
  margin-top: 40px;
}

.ScentAware-chart .ScentAware-chart-button {
  background: #d9d9d9;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
}

.ScentAware-chart .ScentAware-chart-button .active {
  background: #26649d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  color: white;
  border: none;
  padding: 4px 16px;
  cursor: pointer;
}

.ScentAware-chart .ScentAware-chart-button .btn {
  border: none;
  color: #013259;
  background-color: transparent;
  padding: 4px 16px;
  cursor: pointer;
}

.test-log {
  display: flex;
  align-items: baseline;
}

.left-outlined {
  color: #33c9fc;
  display: flex;
  justify-content: center;
}

.test-log h2 {
  margin: 22px;
  font-weight: 300;
  font-size: 26px;
  line-height: 37px;
}

.print {
  display: flex !important;
  justify-content: space-evenly;
  background-color: #001f38;
  color: #33c9fc;
  border-radius: 12px;
  padding: 11px;
  height: 41px;
  width: 145px;
  align-items: center;
  cursor: pointer;
}

.print h5 {
  color: #33c9fc;
  font-size: 14px;
  display: contents;
}

span.anticon.anticon-printer {
  font-size: 18px;
}

.download {
  display: flex !important;
  justify-content: space-evenly;
  background-color: #001f38;
  color: #33c9fc;
  border-radius: 12px;
  padding: 11px;
  height: 41px;
  width: 145px;
  align-items: center;
  cursor: pointer;
}

.download h5 {
  color: #33c9fc;
  font-size: 14px;
  display: contents;
}

span.anticon.anticon-download {
  font-size: 18px;
}

.filters {
  display: flex !important;
  justify-content: space-evenly;
  background-color: #001f38;
  color: #838383;
  border-radius: 12px;
  padding: 11px;
  height: 41px;
  width: 122px;
  align-items: center;
  cursor: pointer;
}

.filters h5 {
  color: #838383;
  font-size: 14px;
  font-size: 14px;
  display: contents;
}

span.anticon.anticon-experiment {
  font-size: 18px;
}

.sort-by {
  display: flex;
  justify-content: space-around !important;
  background-color: #001f38;
  color: #838383;
  border-radius: 12px;
  padding: 11px;
  height: 41px;
  width: 122px;
  align-items: center;
}

.sort-by h5 {
  color: #838383;
  font-size: 14px;
  display: contents;
}

span.anticon.anticon-down {
  font-size: 16px;
  cursor: pointer;
}

.search-input input {
  background-color: #001f38;
  border: 0px;
  height: 41px;
  padding: 17px;
  border-radius: 6px;
  width: 316px;
  border: 0px;
  color: #fff;
  outline: none;
}

span.anticon.anticon-search {
  background: #33c9fc;
  color: #fff;
  height: 40px;
  width: 50px;
  padding: 10px;
  border-radius: 7px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

section.ant-layout {
  background-color: #004180;
}

section.ant-layout.ant-layout-has-sider {
  min-height: calc(100vh - 66px) !important;
}

.btn-Next {
  background-color: #0e84f2;
  border: 0px;
  width: 220px;
  height: 48px;
  border-radius: 8px;
  color: #fff;
  /* font-family: "WorkSans-Regular"; */
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.btn-Cancel {
  border: 0px;
  width: 220px;
  height: 48px;
  border-radius: 8px;
  color: #0e84f2;
  /* font-family: "WorkSans-Regular"; */
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

/* .title-input {
  padding-top: 30px;
  outline: none;
} */
.title-input label {
  font-size: 20px;
  font-weight: 500;
  /* font-family: "WorkSans-Regular"; */
  color: #4e4e4e;
}

.title-input Input,
.title-input select {
  background-color: #f4f4f4;
  width: 615px;
  height: 48px;
  border: 0px;
  border-radius: 7px;
  outline: none;
  font-size: 14px;
}

.description-input TextArea {
  background-color: #f4f4f4;
  width: 615px;
  border: 0px;
  border-radius: 7px;
  outline: none;
  height: 200px !important;
  padding-top: 20px;
  font-size: 16px;
  font-weight: 300;
  /* font-family: "WorkSans-Light"; */
  font-size: 14px;
}

input#fname {
  width: 600px;
  height: 48px;
  border: 0px;
  padding-left: 18px;
  font-size: 17px;
  background-color: #f4f4f4;
  border-radius: 8px;
  /* font-family: "WorkSans-Regular"; */
  outline: none;
}

.description-input label {
  font-size: 20px;
  font-weight: 500;
  /* font-family: "WorkSans-Regular"; */
  color: #4e4e4e;
}

textarea#lname {
  width: 600px;
  border: 0px;
  padding-left: 18px;
  font-size: 17px;
  background-color: #f4f4f4;
  border-radius: 8px;
  font-family: "WorkSans-Regular";
  outline: none;
}

.form-content {
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  /* padding-left: 40px; */
  height: 450px;
  padding-top: 31px;
  border-radius: 10px;
  margin: 0 auto;
}

.form-content5 {
  background-color: #fff;
  width: 100%;
  height: 450px;
  padding: 20px 30px;
  overflow-y: auto;
}

.Card {
  width: 62% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 12px !important;
  margin: 0 auto !important;
}

h1.men-heading {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  /* font-family: "WorkSans-Regular"; */
  color: #4e4e4e;
}

.btns {
  display: flex;
  justify-content: space-evenly;
}

.title-description {
  padding-left: 70px;
}

/* .value-plus-mines {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.duration-trial {
  font-weight: 500;
  font-size: 15px !important;
  /* font-family: "WorkSans-Regular"; */
  line-height: 24px;
}

.trial-in-days {
  font-size: 18px !important;
  /* font-family: "WorkSans-Regular"; */
  font-weight: 500;
  padding-left: 30px;
}

.section-minus {
  background: #ffffff;
  box-shadow: 4px 0px 16px rgb(78 78 78 / 20%);
  border-radius: 8px 0px 0px 8px;
  width: 38px;
  height: 40px;
  text-align: center;
  justify-content: center;
  display: flex;
  font-size: 24px;
  cursor: pointer;
  border: 0px;
}

.section-plus {
  background: #ffffff;
  box-shadow: 4px 0px 16px rgb(78 78 78 / 20%);
  border-radius: 8px 0px 0px 8px;
  width: 38px;
  height: 40px;
  text-align: center;
  justify-content: center;
  display: flex;
  font-size: 24px;
  cursor: pointer;
  outline: none;
  border: 0px;
}

.value-number {
  background-color: #ffffff;
  width: 92px;
  height: 47px;
  font-size: 22px;
  text-align: center;
  border: 0px;
  box-shadow: inset 0px 4px 8px rgba(78, 78, 78, 0.1);
  border-radius: 8px;
  outline: none;
}

.value-plus-minus {
  display: flex;
  justify-content: center;
}

.value-plus-minus input {
  width: 92px;
  border: 0px;
  font-size: 20px;
  outline: none;
}

.value-dd {
  background-color: #ffffff;
  width: 70px !important;
  height: 43px;
  font-size: 22px !important;
  text-align: center;
  border: 0px !important;
  box-shadow: inset 0px 4px 8px rgb(78 78 78 / 10%);
  border-radius: 8px !important;
  outline: none;
}

.value-mm {
  background-color: #ffffff;
  width: 70px !important;
  height: 43px;
  font-size: 22px !important;
  text-align: center;
  border: 0px !important;
  box-shadow: inset 0px 4px 8px rgb(78 78 78 / 10%);
  border-radius: 8px !important;
  outline: none;
}

.value-y {
  background-color: #ffffff;
  width: 70px !important;
  height: 43px;
  font-size: 22px !important;
  text-align: center;
  border: 0px !important;
  box-shadow: inset 0px 4px 8px rgb(78 78 78 / 10%);
  border-radius: 8px !important;
  outline: none;
}

.btn-Previous {
  border: 0px;
  width: 220px;
  height: 48px;
  border-radius: 8px;
  color: #0e84f2;
  /* font-family: "WorkSans-Regular"; */
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.first-section {
  margin-top: 34px;
}

.section-input-two {
  margin-bottom: 52px;
  margin-top: 40px;
}

.fwidth-search-input {
  width: 100%;
  margin-bottom: 20px;
  outline: none;
  display: flex;
}

.fwidth-search-input button {
  color: #ffff;
  border: 0px;
  background-color: #0e84f2;
  height: 37px;
  width: 60px;
  /* font-family: "WorkSans-Regular"; */
  border-radius: 8px;
  margin-left: 10px;
  font-size: 16px;
  cursor: pointer;
}

.fwidth-search-input1 {
  width: 100%;
  margin-bottom: 20px;
  outline: none;
  display: flex;
}

.fwidth-search-input1 button {
  color: #ffff;
  border: 0px;
  background-color: #0e84f2;
  height: 37px;
  width: 60px;
  /* font-family: "WorkSans-Regular"; */
  border-radius: 8px;
  margin-left: 10px;
  font-size: 16px;
}

.fwidth-search-input input {
  width: 600px;
  height: 40px;
  border-radius: 19px;
  border: 0px;
  box-shadow: inset 0px 36px 24px rgb(102 78 94 / 10%);
  padding: 21px;
  outline: none;
  margin-left: 10px;
}

.fwidth-search-input1 input {
  width: 600px;
  height: 40px;
  border-radius: 19px;
  border: 0px;
  box-shadow: inset 0px 36px 24px rgb(102 78 94 / 10%);
  padding: 21px;
  outline: none;
}

.fwidth-search-input input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  width: 20px;
  height: 20px;
}

.btn-selected {
  background: transparent;
  color: #0e84f2;
  border: 0px;
  font-size: 18px;
  cursor: pointer;
  margin: 0 auto;
  display: flex;
  margin-right: 33px;
}

.add-participants-section {
  text-align: start;
  margin-left: 18px;
}

.btn-Previous2 {
  border: 0px;
  width: 220px;
  height: 48px;
  border-radius: 8px;
  color: #0e84f2;
  /* font-family: "WorkSans-Regular"; */
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  background-color: transparent;
}

.add-moderators-section {
  margin: 0 auto !important;
  /* padding-top: 0px;
  padding-bottom: 9px; */
  padding-left: 6px;
}

.avatar-details {
  margin: 16px;
  display: flex;
  align-items: center;
}

.avatar-details3 {
  margin: 16px;
  margin-bottom: 19px;
}

.avatar-details5 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.avatar-details.review {
  margin: 16px 0px;
}

.review-details h4 {
  color: #4e4e4e;
  font-size: 16px;
  font-weight: 500;
  /* font-family: "WorkSans-Bold"; */
}

.review-details {
  margin-top: 25px;
}

.review-details h2 {
  color: #4e4e4e;
  font-size: 24px;
  font-weight: 400;
  /* font-family: "WorkSans-Light"; */
}

.review-details p {
  color: #4e4e4e;
  font-size: 12px;
  font-weight: 500;
  /* font-family: "WorkSans-Regular"; */
}

.review-date {
  display: flex;
  align-items: center;
}

.review-date .title {
  color: #4e4e4e;
  font-size: 12px;
  font-weight: 500;
  /* font-family: "WorkSans-Regular"; */
  margin: 0;
  margin-right: 20px;
}

.review-date .days {
  color: #4e4e4e;
  font-size: 12px;
  /* font-family: "WorkSans-bold"; */
  margin: 0;
  margin-right: 30px;
}

.duration-section h4 {
  color: #4e4e4e;
  font-size: 15px;
  font-weight: 500;
  /* font-family: "WorkSans-Regular"; */
}

.duration-section h5 {
  color: #4e4e4e;
  font-size: 14px;
  font-weight: 700;
  /* font-family: "WorkSans-Regular"; */
}

.duration-section h3 {
  color: #4e4e4e;
  font-size: 14px;
  font-weight: 700;
  /* font-family: "WorkSans-Light"; */
}

.Moderators {
  color: #4e4e4e;
  font-size: 18px;
  font-weight: 700;
  /* font-family: "WorkSans-Regular"; */
}

.participants {
  font-size: 18px;
  font-weight: 700;
  /* font-family: "WorkSans-Regular"; */
}

.avatar-name {
  font-size: 14px;
  font-weight: 600;
  /* font-family: "WorkSans-Regular"; */
}

.md-psychology {
  font-weight: 300;
  font-size: 14px;
  /* font-family: "WorkSans-Light"; */
}

.para-review-succes {
  font-weight: 500;
  font-size: 18px;
  /* font-family: "WorkSans-Light"; */
  text-align: center;
  margin: 100px;
}

.smell-over {
  font-weight: 800;
}

/* .form-content6 {
  background-color: #fff;
  width: 65%;
  margin: 0 auto;
  padding-left: 40px;
  height: 500px;
  padding-top: 31px;
  border-radius: 10px;
} */

h1.Trial.create.successfully\! {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  /* font-family: "WorkSans-Regular"; */
  color: #4e4e4e;
}

.ant-picker {
  height: 40px !important;
  margin-top: 23px !important;
  border: 0px !important;
  background: #f4f4f4 !important;
  width: 92% !important;
}

input.ant-input {
  background: #f4f4f4 !important;
  /* width: 77px !important; */
  height: 43px !important;
  border: 0px;
  justify-content: center !important;
  padding: 20px;
}

.input.ant-input.section-120 {
  background: #f4f4f4 !important;
  width: 77px !important;
  height: 43px !important;
  border: 0px;
  justify-content: center !important;
  padding: 20px;
}

.ant-picker {
  margin: 4px !important;
}

.ant-form-item-control-input-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-start-date {
  font-size: 16px !important;
  font-weight: 500 !important;
  /* font-family: "WorkSans-Regular"; */
}

.label-start-date {
  font-size: 16px !important;
  font-weight: 500 !important;
  /* font-family: "WorkSans-Regular" !important; */
}

.start-date-section label {
  font-size: 17px !important;
  /* font-family: "WorkSans-Regular"; */
  padding-left: 32px;
}

.duration-trial label {
  color: #4e4e4e;
  font-size: 22px !important;
  /* font-family: "WorkSans-Regular" !important; */
  margin-left: 26px !important;
}

input.ant-input.section-120 {
  width: 77px;
}

.title-input label {
  display: block !important;
}

.ant-row.ant-form-item-row {
  display: block;
}

.ant-form-item-label>label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px !important;
  margin-left: 35px;
  /* font-family: "WorkSans-Regular"; */
  margin: 0 auto;
}

.next-page {
  border: 0px;
  width: 220px !important;
  height: 48px !important;
  border-radius: 8px !important;
  color: #fff !important;
  /* font-family: "WorkSans-Regular" !important; */
  font-size: 16px !important;
  font-weight: 700 !important;
  cursor: pointer !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.done-success {
  border: 0px;
  width: 220px !important;
  height: 48px !important;
  border-radius: 8px !important;
  color: #fff !important;
  /* font-family: "WorkSans-Regular" !important; */
  font-size: 16px !important;
  font-weight: 700 !important;
  cursor: pointer !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.btn-previous {
  border: 0px;
  width: 220px !important;
  height: 48px !important;
  border-radius: 8px !important;

  /* font-family: "WorkSans-Regular" !important; */
  font-size: 16px !important;
  font-weight: 700 !important;
  cursor: pointer !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.create-clinic {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 400;
  /* font-family: "WorkSans-Regular"; */
}

.ant-row.form-content {
  display: block;
}

form.ant-form.ant-form-horizontal .ant-row {
  width: 100%;
}

form.ant-form.ant-form-horizontal input,
form.ant-form.ant-form-horizontal textarea {
  width: 100% !important;
}

form.ant-form.ant-form-horizontal input,
.ant-picker {
  width: 100% !important;
  margin: 0px !important;
}

form.ant-form.ant-form-horizontal label.ant-form-item-required {
  font-weight: 800;
}

/*CSS*/

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.table-header .title {
  color: white;
  font-weight: 600;
}

.table-header .sub-title {
  color: white;
  font-weight: 300;
}

.header-icon {
  display: flex;
  color: white;
}

.header-icon svg {
  color: #33c9fc;
}

h4.sub-heading {
  font-size: 16px;
  margin-top: 16px;
  font-weight: 300 !important;
}

.sub-heading svg {
  margin-left: 20px;
}

.sub-heading .traceaware-option select {
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: 300 !important;
  cursor: pointer;
}

.sub-heading .traceaware-option select option {
  background-color: rgb(1, 50, 89);
}

.ant-list.ant-list-split::-webkit-scrollbar {
  background-color: transparent;
  width: 2px;
}

.ant-table-content,
.ant-table-thead>tr>th {
  background-color: #013259 !important;
}

.ant-table-tbody>tr.ant-table-row:hover>td {
  background-color: #013259 !important;
}

.ant-table-tbody>tr>td,
.ant-table-thead>tr>th {
  border-bottom: 2px solid rgba(255, 255, 255, 0.2) !important;
  color: white !important;
}

th.ant-table-cell {
  font-weight: bold !important;
  font-size: 14px !important;
}

li.ant-pagination-item.ant-pagination-item-1.ant-pagination-item-active {
  background-color: #0e84f2;
  width: 45px !important;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 0px;
}

li.ant-pagination-item a {
  color: white;
}

li.ant-pagination-item {
  width: 45px !important;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: transparent;
  border: 2px solid #b9b9ba;
}

ul.ant-pagination.ant-table-pagination.ant-table-pagination-right {
  display: flex;
  align-items: center;
}

button.ant-pagination-item-link {
  background-color: transparent !important;
  border: 0px !important;
}

button.ant-pagination-item-link svg {
  color: #ffffff;
  font-size: 15px !important;
}

.test-log svg {
  color: #33c9fc;
  width: 20px;
  margin-bottom: 5px;
}

.scroll {
  overflow-x: auto;
  overflow-y: hidden;
}

.scroll::-webkit-scrollbar {
  height: 8px;
}

.scroll::-webkit-scrollbar-track {
  background: rgba(239, 239, 239, 0.2);
  border-radius: 100px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 100px;
}

.participants-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
}

::-webkit-scrollbar {
  width: 8px;
  scrollbar-width: thin;
}

::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(78, 78, 78, 0.2);
}

::-webkit-scrollbar-track {
  background: rgba(126, 126, 126, 0.2);
  border-radius: 16px;
}

.ant-empty-description {
  color: #ffffff;
}

/*=== scentaware ===*/
.page_section {
  padding-top: 96px;
  padding-bottom: 40px;
}

.view_test_button .btn.btn-primary {
  height: 56px;
  min-width: 198px;
}

li.seprator {
  opacity: 0.3;
  background: #5800ff;
  width: 2px !important;
  height: 32px;
  padding: 0 !important;
}

.text_log_card {
  width: 100%;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(78, 78, 78, 0.25);
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text_log_card ul {
  gap: 0px;
  justify-content: space-between;
  width: 100%;
}

.text_log_card ul li {
  flex: 0 0 auto;
  width: 33.33%;
  text-align: left;
  align-items: center;
  padding: 0 35px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  justify-content: space-between;
  display: flex;
}

.text_log_card ul li b {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.page_title {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.scent_aware .col {
  width: auto;
  flex: 0 0 auto;
}

.scent_aware_col {
  width: 69.6% !important;
}

/*=== ScentAware graph ===*/

.graph_body.inner_graph {
  min-height: 150px;
}

.pie_list ul li span {
  display: inline-block;
  width: 24px;
  height: 24px;
  flex: 0 0 auto;
  background: #004ab7;
  border-radius: 50px;
}

.pie_list ul li {
  gap: 24px;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  align-items: center;
}

.pie_list ul li:not(:last-child) {
  margin-bottom: 24px;
}

.pie_list ul li span.bg_blue {
  background: #0e84f2;
}

.pie_list ul li span.bg_light_b {
  background: #99e7fd;
}

/*=== test log ===*/
.view_test_button .dropdown .btn.btn-primary {
  min-width: 166px;
}

button.search_button {
  width: 56px;
  height: 56px;
  border-radius: 16px !important;
  background: #0096ff;
  border: none;
}

.input-group {
  border-radius: 16px;
  background: #f2f2f2;
  min-width: 100%;
}

.input-group input,
.input-group input::placeholder {
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
  outline: none !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #838383;
}

.test_table thead tr th,
.test_table tbody tr td {
  color: #101a36;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  vertical-align: middle;
}

.test_table thead tr th {
  padding: 16px;
  border-bottom: 1px solid #101a36;
}

.test_table tbody tr td {
  border-bottom: 1px solid #101a36;
  padding: 16px;
}

.test_table thead tr th {
  white-space: nowrap;
}

.test_table tbody tr td {
  font-weight: 500;
}

.test_table {
  overflow: auto;
}

.pagination p {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: #101a36;
}

.pagination p b {
  font-weight: 600;
}

span.pagination_count {
  border-radius: 16px;
  border: 2px solid var(--Cool-Blue, #0096ff);
  width: 51px;
  height: 51px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #101a36;
  transition: 0.5s;
}

span.pagination_count.active,
span.pagination_count:hover {
  background: #0096ff;
  color: #fff;
  transition: 0.5s;
  cursor: pointer;
}

/*===super clinical ===*/
.prince_ton_card h4 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #101a36;
  max-width: 65%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prince_ton_card ul li {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  color: #101a36;
}

.new_trial hr {
  background: #d9d9d9;
}

.new_trial button.btn.btn-primary {
  width: 94.505%;
  height: 53px;
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  box-shadow: 0px 8px 8px 0px rgba(78, 78, 78, 0.25) !important;
}

.prince_ton_card.prince_active {
  background: #d1f3ff;
  border-radius: 4px;
}

.prince_ton_card.prince_active h4 {
  font-weight: 600;
}

.prince_ton_card.prince_active ul li {
  font-weight: 400;
}

.small_acutiy_card h3 {
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.96px;
  color: #101a36;
}

.small_acutiy_card p {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #101a36;
}

.small_acutiy_card a {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #101a36;
}

.moderators h3 {
  color: #101a36;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.maderators_icon {
  width: 40px;
  height: 40px;
  background: #9747ff;
  color: #fff;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

.maderators_info h3 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 4px;
}

.maderators_info p {
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: #101a36;
}

.test_table tbody tr td a {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #0096ff;
  width: 100%;
  white-space: nowrap;
  text-align: right;
  display: block;
}

/*=== active user ===*/
.search_icon_inner {
  border-radius: 8px;
  border: 1px solid #101a36;
  background: #fff;
  height: 48px;
  padding: 0;
  overflow: hidden;
  position: relative;
}

.search_icon {
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cross_icon {
  position: absolute;
  right: 8px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="search" i]::-webkit-search-cancel-button {
  display: none;
}

.search_icon_inner input {
  border: none;
  padding: 0 0 0 36px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #7e7e7e;
  height: 100%;
  outline: none;
  box-shadow: none !important;
}

ul.user_list li {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 20px;
  color: #101a36;
}

ul.user_list li.active {
  background: #a1e7ff;
  font-weight: 700;
}

ul.user_list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

ul.user_list li button.accordion-button {
  padding: 8px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #101a36;
  line-height: 24px;
  outline: none;
  box-shadow: none;
  background-color: transparent !important;
  width: 100%;
}

ul.user_list>li:last-child {
  padding: 0;
}

ul.user_list li .accordion-body ul li {
  padding: 8px 16px;
}

.accordion-button::after {
  background-image: url("assets/images/accordion_arrow.png");
  background-size: 24px 24px;
  background-position: center;
  width: 24px;
  height: 24px;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("assets/images/accordion_arrow.png");
}

.user_list ul li {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #101a36;
  padding: 4px 8px;
  gap: 12px;
}

.user_name {
  flex: 0 0 auto;
  width: 32px;
  border-radius: 100%;
  height: 32px;
  background: #0096ff;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  text-transform: uppercase;
  text-align: center;
}

.user_list ul {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.user_list ul li.active {
  background: #0096ff;
  color: #fff;
}

.small_acutiy_card.hanna h3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #101a36;
}

.small_acutiy_card.hanna h3 .btn {
  padding: 8px 24px;
}

.user_address ul li {
  color: #101a36;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.user_address ul {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.user_address {
  display: flex;
  gap: 78px;
}

.small_acutiy_card.hanna table tr td {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #101a36;
  width: 10%;
  padding: 8px 16px;
  white-space: nowrap;
  border-bottom: 1px solid #b9b9ba;
}

.small_acutiy_card.hanna table tr td:nth-child(2) {
  text-align: center;
  font-weight: 400;
}

.small_acutiy_card.hanna table tr td:last-child {
  text-align: right;
}

.user_table {
  overflow: auto;
  width: 100%;
}

.css-b62m3t-container {
  width: 100%;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.css-13cymwt-control .css-3w2yfm-ValueContainer,
.css-t3ipsp-control .css-3w2yfm-ValueContainer,
.css-13cymwt-control .css-1dyz3mf,
.css-t3ipsp-control .css-1dyz3mf {
  max-height: 40px;
  overflow: auto;
}

.css-1jqq78o-placeholder {
  text-align: start;
}

/*=== mobile view ===*/
@media (max-width: 1440px) {
  .scent_aware_col {
    width: 66.4% !important;
  }
}

@media (max-width: 1370px) {
  .graph_idicate_outer li {
    font-size: 14px;
  }

  .btn.btn-primary {
    padding: 8px 15px;
    font-size: 12px;
  }

  .sidebar ul li a {
    font-size: 12px;
  }

  .sidebar ul.nav {
    gap: 28px;
  }

  .scent_aware_col {
    width: 66% !important;
  }
}

@media (max-width: 1300px) {
  .scent_aware .col {
    width: 100%;
    flex: 0 0 auto;
    justify-content: center !important;
  }

  .scent_aware_col {
    width: 100% !important;
  }

  .scent_aware {
    gap: 24px;
  }

  .pie_list ul li {
    font-size: 13px;
  }

  .view_test_button .btn.btn-primary {
    height: 56px;
    min-width: 167px;
  }
}

@media (max-width: 1200px) {
  .graph_idicate_outer li {
    font-size: 13px;
  }

  .page_wrapper {
    padding: 74px 0px 40px 0;
  }

  .graph_title h3 {
    font-size: 15px;
  }

  .sidebar {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    width: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0;
    overflow: hidden;
    transition: 0.5s;
  }

  .pie_list ul li:not(:last-child) {
    margin-bottom: 10px;
  }

  .pie_list ul li {
    font-size: 11px;
  }

  .sidebar.show {
    width: 100%;
    padding: 20px;
    transition: 0.5s;
    height: auto;
  }

  .sidebar ul li a {
    justify-content: center;
    align-items: center;
  }

  .sidebar ul.nav {
    gap: 28px;
  }

  .view_test_button .btn.btn-primary {
    min-width: auto;
    flex-basis: 1;
    flex: auto;
  }

  .page_title {
    font-size: 21px;
  }

  .page_section {
    padding-top: 74px;
    padding-bottom: 40px;
  }
}

@media (max-width: 1100px) {
  .page_title {
    font-size: 18px;
  }

  .page_title svg {
    width: 30px;
  }
}

@media (max-width: 990px) {
  .text_log_card ul li {
    padding: 0 20px;
    font-size: 12px;
  }

  .page_title {
    font-size: 21px;
  }

  .view_test_button .btn.btn-primary {
    height: 50px;
  }

  .test_table thead tr th,
  .test_table tbody tr td {
    font-size: 11px;
  }

  .test_table thead tr th {
    padding: 10px;
  }

  span.pagination_count {
    width: 41px;
    height: 41px;
    font-size: 14px;
  }

  button.search_button {
    width: 41px;
    height: 41px;
  }

  .user_list {
    max-height: 240px;
    overflow: auto;
  }
}

@media (max-width: 767px) {
  .graph_card_outer {
    padding: 25px 15px;
  }

  .user_list {
    max-height: 140px;
    overflow: auto;
  }

  .btn.btn-primary {
    padding: 8px 15px;
    white-space: nowrap;
    font-size: 12px;
  }

  .graph_idicate_outer li {
    font-size: 11px;
    white-space: nowrap;
  }

  .graph_idicate_outer li svg {
    width: 30px;
  }

  .graph_button button.dropdown-toggle {
    font-size: 11px;
  }

  .graph_title h3 {
    font-size: 14px;
  }

  .graph_button h6 {
    font-size: 11px;
  }

  .user_info h4 {
    font-size: 11px;
  }

  .brand img {
    width: 100px;
  }

  .text_log_card ul {
    flex-direction: column;
  }

  .text_log_card {
    height: auto;
    padding: 15px;
  }

  .text_log_card ul li {
    padding: 0 0px;
    font-size: 12px;
    width: 100%;
  }

  li.seprator {
    width: 100% !important;
    height: 2px;
    margin: 10px 0;
  }

  .small_acutiy_card.hanna table tr td {
    padding: 8px 5px;
  }

  .small_acutiy_card a {
    padding: 0;
  }

  .view_test_button .btn.btn-primary {
    height: auto;
  }

  .view_test_button .btn.btn-primary {
    min-width: auto;
    flex-basis: 1;
    flex: auto;
  }

  /* .pagination {
        flex-direction: column;
        gap: 30px;
    } */

  span.pagination_count {
    width: 24px;
    height: 24px;
    font-size: 11px;
    border-radius: 8px;
  }

  .pagination p {
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    color: #101a36;
    white-space: nowrap;
  }

  .previour svg,
  .next svg {
    width: 18px;
    height: 18px;
  }
}

section.ant-layout {
  background-color: #004180;
}

section.ant-layout.ant-layout-has-sider {
  min-height: calc(100vh - 0px) !important;
}

.login {
  background-image: url("assets/images/Dashboard_login_hero.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

form#basic {
  background-color: #013259;
  padding: 15px;
  width: fit-content;
  width: 382px;
  padding: 40px;
  margin-top: 150px;
  border-radius: 8px;
}

.ant-form-item-label>label {
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  display: inline-flex;
  font-size: 14px;
  height: 32px;
  max-width: 100%;
  position: relative;
}

.ant-form-item-label>label {
  font-size: 18px !important;
  margin: 0 auto;
}

input#basic_email {
  width: 100%;
}

button.ant-btn.ant-btn-primary {
  background: #26649d;
  border-radius: 8px;
  width: 50%;
  height: 40px;
  font-weight: bolder;
}

button.ant-btn.ant-btn-default.nextStep-page {
  width: 50%;
  height: 40px;
  border-radius: 5px;
}

form#basic h5 {
  display: flex;
  line-height: 18px;
  cursor: pointer;
  text-decoration: underline;
  justify-content: right;
  padding-right: 22px;
  padding-bottom: 30px;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #33c9fc;
}

form#basic h4 {
  font-weight: 500 !important;
  font-size: 20px;
  line-height: 28px;
  color: #4e4e4e;
  padding-bottom: 19px;
}

.ant-form-vertical .ant-form-item .ant-form-item-control {
  margin: 0px;
}

.Main-field {
  display: flex;
  justify-content: end;
  width: 92%;
}

label.ant-form-item-required {
  font-weight: 500;
  color: #4e4e4e;
  font-style: normal;
  line-height: 19px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.input-field h4 {
  font-size: 24px;
  margin: 0px;
  font-weight: normal;
}

.Main-field input,
span.ant-input-affix-wrapper.ant-input-password {
  border-color: #26649d !important;
  height: 40px;
  border-radius: 5px !important;
  outline: none;
}

button.ant-btn.ant-btn-default.ant-btn-lg.default_btn.colored_btn {
  background-color: #26649d;
  width: 235px;
  height: 50px;
  border-radius: 12px;
  color: #fff;
  margin-top: 20px;
}

button.ant-btn.ant-btn-default.ant-btn-lg.default_btn.transparent_btn {
  width: 235px;
  height: 50px;
  color: #26649d;
  margin-top: 20px;
  border: 0px !important;
}

form#basic .colored_btn {
  width: 100%;
}

.forget-section {
  align-items: center;
  display: flex;
  height: 90%;
  justify-content: center;
  margin: 0;
}

.ant-row {
  margin: 0 auto !important;
}

.ant-row.form-content {
  display: block;
}

.input.ant-input.section-120,
input.ant-input {
  background: #f4f4f4 !important;
  border: 0;
  height: 43px !important;
  justify-content: center !important;
  padding: 20px;
}

.description-input TextArea,
.title-input Input,
.title-input select {
  border-radius: 7px;
  outline: none;
}

.title-input Input,
.title-input select {
  font-size: 14px;
}

.ant-input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

textarea.ant-input {
  height: auto;
  line-height: 1.5715;
  max-width: 100%;
  min-height: 32px;
  transition: all 0.3s, height 0s;
  vertical-align: bottom;
}

.description-input TextArea,
.title-input Input,
.title-input select,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f4f4f4 !important;
  border: 0 !important;
  border-radius: 7px !important;
  outline: none;
  min-height: 43px !important;
  align-items: center;
}

.description-input TextArea {
  font-size: 14px;
  font-weight: 300;
  height: 200px !important;
  padding-top: 20px;
}

.create-clinic {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0.5em;
}

.ant-card-bordered {
  box-shadow: 0 0 10px 0 #00000036;
}

.btn-previous,
.create-clinic,
.done-success,
.next-page {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ant-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.forget-section input {
  border-color: #26649d !important;
  border-radius: 5px !important;
  height: 40px;
}

.ant-card {
  font-feature-settings: "tnum", "tnum";
  background: #fff;
  border-radius: 2px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.ant-card-body {
  padding: 24px;
  max-width: 967px;
}

.Card {
  align-items: center !important;
  border-radius: 12px !important;
  display: flex !important;
  justify-content: center !important;
  margin: 0 auto !important;
  width: fit-content !important;
}

.create-clinic {
  font-size: 24px;
  font-weight: 400;
}

.ant-steps {
  font-feature-settings: "tnum", "tnum";
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  font-size: 14px;
  font-size: 0;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  text-align: initial;
  width: 100%;
}

.ant-steps-item {
  display: inline-block;
  flex: 0 0 auto !important;
  overflow: hidden;
  position: relative;
  vertical-align: top;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 16px;
  white-space: nowrap;
}

.ant-steps-item-container {
  outline: none;
}

.ant-steps-item-tail {
  left: 0;
  padding: 0 10px;
  position: absolute;
  top: 12px;
  width: 100%;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}

.ant-steps-item-content,
.ant-steps-item-icon {
  display: inline-flex !important;
  vertical-align: top;
}

.add-participants-section h2 {
  font-size: 21px;
}

.ant-steps-item-icon {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  font-size: 16px;
  height: 32px;
  line-height: 32px;
  margin: 0 8px 0 0;
  text-align: center;
  transition: background-color 0.3s, border-color 0.3s;
  width: 32px;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #1890ff;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
  background: #1890ff;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}

.ant-steps-item-icon .ant-steps-icon {
  color: #1890ff;
  line-height: 1;
  position: relative;
  top: -0.5px;
}

.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
  color: #1890ff;
}

.ant-steps-item-icon,
.ant-steps-item-content {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}

.ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
  display: inline-block;
  font-size: 16px;
  line-height: 32px;
  padding-right: 16px;
  position: relative;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
  background-color: #f0f0f0;
}

.ant-steps-item-title:after {
  background: #f0f0f0;
  content: "";
  display: block;
  height: 1px;
  left: 100%;
  position: absolute;
  top: 16px;
  width: 9999px;
}

.ant-form {
  font-feature-settings: "tnum", "tnum";
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  margin: 0;
  padding: 0;
}

form.ant-form.ant-form-horizontal .ant-row {
  width: 100%;
}

.form-content,
.form-content5 {
  background-color: #fff;
  height: 450px;
  width: 100%;
}

.form-content {
  border-radius: 10px;
  margin: 0 auto;
  padding-top: 31px;
}

.ant-form-item {
  font-feature-settings: "tnum", "tnum";
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  margin: 0 0 24px;
  padding: 0;
  vertical-align: top;
}

form.ant-form.ant-form-horizontal .ant-row {
  width: 100%;
}

.ant-row.ant-form-item-row {
  display: block;
}

.ant-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  text-align: right;
  vertical-align: middle;
  white-space: nowrap;
}

.ant-col {
  max-width: 100%;
  min-height: 1px;
  position: relative;
}

.ant-form-item-label>label {
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  display: inline-flex;
  font-size: 14px;
  height: 32px;
  max-width: 100%;
  position: relative;
}

form.ant-form.ant-form-horizontal label.ant-form-item-required {
  font-weight: 500;
}

.ant-form-item-label>label {
  font-size: 18px !important;
  margin: 0 auto;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  color: #ff4d4f;
  content: "*";
  display: inline-block;
  font-size: 14px;
  line-height: 1;
  margin-right: 4px;
}

.ant-form-item-label>label:after {
  content: ":";
  margin: 0 8px 0 2px;
  position: relative;
  top: -0.5px;
}

.ant-form-item-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ant-col {
  max-width: 100%;
  min-height: 1px;
  position: relative;
}

.ant-form-horizontal .ant-form-item-control {
  flex: 1 1;
  min-width: 0;
}

.ant-form-item-control-input {
  align-items: center;
  display: flex;
  min-height: 32px;
  position: relative;
}

.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}

.ant-form-item-control-input-content {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ant-picker,
form.ant-form.ant-form-horizontal input {
  margin: 0 !important;
  width: 100% !important;
}

.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}

.users_status {
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-left: 12px;
  cursor: pointer;
}

.users_status.active {
  background: green;
}

.users_status.inactive {
  background-color: red;
}

.steps-content .form-content,
.steps-content .form-content5 {
  min-height: 584px;
  height: auto;
}

.search_form .table tr th,
.search_form .table tr td {
  font-size: 10px;
  white-space: nowrap;
  padding: 10px 5px;
}

/*=== mobile view ===*/
@media (max-width: 1440px) {
  .scent_aware_col {
    width: 66.4% !important;
  }
}

@media (max-width: 1370px) {
  .graph_idicate_outer li {
    font-size: 11px;
    white-space: nowrap;
  }

  .graph_idicate_outer li a svg {
    width: 30px;
    height: 30px;
  }

  .btn.btn-primary {
    padding: 8px 13px;
    font-size: 11px;
  }

  .sidebar ul li a {
    font-size: 12px;
  }

  .sidebar ul.nav {
    gap: 28px;
  }

  .scent_aware_col {
    width: 66% !important;
  }
}

@media (max-width: 1300px) {
  .scent_aware .col {
    width: 100%;
    flex: 0 0 auto;
    justify-content: center !important;
  }

  .scent_aware_col {
    width: 100% !important;
  }

  .scent_aware {
    gap: 24px;
  }

  .pie_list ul li {
    font-size: 13px;
  }

  .view_test_button .btn.btn-primary {
    height: 56px;
    min-width: 167px;
  }
}

@media (max-width: 1200px) {
  .graph_idicate_outer li {
    font-size: 11px;
  }

  .page_wrapper {
    padding: 74px 0px 40px 0;
  }

  .graph_title h3 {
    font-size: 15px;
  }

  .sidebar {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    width: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0;
    overflow: hidden;
    transition: 0.5s;
  }

  .pie_list ul li:not(:last-child) {
    margin-bottom: 10px;
  }

  .pie_list ul li {
    font-size: 11px;
  }

  .sidebar.show {
    width: 100%;
    padding: 20px;
    transition: 0.5s;
    height: auto;
  }

  .sidebar ul li a {
    justify-content: center;
    align-items: center;
  }

  .sidebar ul.nav {
    gap: 28px;
  }

  .view_test_button .btn.btn-primary {
    min-width: auto;
    flex-basis: 1;
    flex: auto;
  }

  .page_title {
    font-size: 21px;
  }

  .page_section {
    padding-top: 74px;
    padding-bottom: 40px;
  }
}

@media (max-width: 1100px) {
  .page_title {
    font-size: 18px;
  }

  .page_title svg {
    width: 30px;
  }
}

@media (max-width: 990px) {
  .text_log_card ul li {
    padding: 0 20px;
    font-size: 12px;
  }

  .page_title {
    font-size: 21px;
  }

  .view_test_button .btn.btn-primary {
    height: 50px;
  }

  .test_table thead tr th,
  .test_table tbody tr td {
    font-size: 11px;
  }

  .test_table thead tr th {
    padding: 10px;
  }

  span.pagination_count {
    width: 41px;
    height: 41px;
    font-size: 14px;
  }

  button.search_button {
    width: 41px;
    height: 41px;
  }

  .user_list {
    max-height: 240px;
    overflow: auto;
  }
}

@media (max-width: 767px) {
  .graph_card_outer {
    padding: 25px 15px;
  }

  .user_list {
    max-height: 140px;
    overflow: auto;
  }

  .btn.btn-primary {
    padding: 8px 15px;
    white-space: nowrap;
    font-size: 12px;
  }

  .graph_idicate_outer li {
    font-size: 11px;
    white-space: nowrap;
  }

  .graph_idicate_outer li svg {
    width: 30px;
  }

  .graph_button button.dropdown-toggle {
    font-size: 11px;
  }

  .graph_title h3 {
    font-size: 14px;
  }

  .graph_button h6 {
    font-size: 11px;
  }

  .user_info h4 {
    font-size: 11px;
  }

  .brand img {
    width: 100px;
  }

  .text_log_card ul {
    flex-direction: column;
  }

  .text_log_card {
    height: auto;
    padding: 15px;
  }

  .text_log_card ul li {
    padding: 0 0px;
    font-size: 12px;
    width: 100%;
  }

  li.seprator {
    width: 100% !important;
    height: 2px;
    margin: 10px 0;
  }

  .small_acutiy_card.hanna table tr td {
    padding: 8px 5px;
  }

  .small_acutiy_card a {
    padding: 0;
  }

  .view_test_button .btn.btn-primary {
    height: auto;
  }

  .view_test_button .btn.btn-primary {
    min-width: auto;
    flex-basis: 1;
    flex: auto;
  }

  /* .pagination {
      flex-direction: column;
      gap: 30px;
  } */

  span.pagination_count {
    width: 24px;
    height: 24px;
    font-size: 11px;
    border-radius: 8px;
  }

  .pagination p {
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    color: #101a36;
    white-space: nowrap;
  }

  .previour svg,
  .next svg {
    width: 18px;
    height: 18px;
  }
}

/* Tempware css */
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");

* {
  box-sizing: border-box;
}

.tempwareBody {
  margin: 0;
  padding: 0;
  position: relative;
  font-family: "Work Sans", sans-serif;
  background-color: #061e2d;
}

a {
  text-decoration: none;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d6d5d5;
  border-radius: 4px;
  background-color: #d6d5d5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #79747e;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #79747e;
}

/*=== header ====*/
.userInfo {
  color: #a1e7ff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sidebarNewNavMenu li a {
  color: #c0c8cc;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.sidebarNewNavMenu li.active {
  border-radius: 100px;
  background: #603768;
}

.sidebarNewNavMenu li.active a {
  font-weight: 600;
  color: #fdd6ff;
}

.sidebarNewNavMenu li.active a svg,
.sidebarNewNavMenu li.active a svg path {
  fill: #fdd6ff;
}

header.Header {
  position: fixed;
  top: 0;
  z-index: 999;
  background-color: #101a36;
  width: 100%;
}

aside.sidebarNew {
  flex: 0 0 auto;
  width: 13.021vw;
  height: calc(100vh - 30px);
  position: fixed;
  top: 24px;
  left: 24px;
  bottom: 0;
  overflow-x: auto;
}

/* 

.sidebarNewNavMenu  li a.active .icon {
  border-radius: 8px;
  background: #00D7FF;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidemenu li a.active .icon svg path {
  stroke: #101A36;
}


.sidemenu li a.active {
  color: #00D7FF;
} */

.MenuTitle h3 {
  margin: 0;
  color: #c0c8cc;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.hamburger {
  display: none;
}

/*=== page boxy ===*/
section.pageOuter {
  margin-top: 0px;
  padding: 24px;
}

.pageBody {
  flex: 0 0 auto;
  width: 100%;
  max-width: calc(100vw - 15.81vw);
  min-height: calc(100vh - 72px);
}

.divider {
  width: calc(100% - 32px);
  height: 1px;
  background: #40484c;
  margin: auto;
}

.pageCard {
  border-radius: 16px;
  background: #09283e;
}

.DashboardSearch input {
  border-radius: 28px;
  background: #252b2c;
  border: none;
  box-shadow: none;
  outline: none;
  min-height: 56px;
  padding: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #c0c8cc;
  width: 100%;
  padding-left: 56px;
  padding-right: 56px;
}

.DashboardSearch input::placeholder {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #c0c8cc;
}

.crossIcon {
  width: 48px;
  height: 48px;
  position: absolute;
  right: 4px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DashboardSearch input[type="search" i]:enabled:read-write:-webkit-any( :focus,
  :hover)::-webkit-search-cancel-button {
  display: none;
}

.DashboardSearch .searchIcon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  left: 4px;
}

.cardHeading {
  color: #dee3e5;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.deviceCard {
  border-radius: 8px;
  background: #0d3959;
}

.diviceCheckBox input {
  opacity: 0;
  z-index: 999;
  width: 24px;
  height: 24px;
  position: absolute;
}

.diviceCheckBox span.checked {
  position: absolute;
  opacity: 0;
}

.deviceCard h4 {
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #dee3e5;
}

.deviceCard p {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #c0c8cc;
  max-width: 165px;
}

.diviceCheckBox input:checked~span.checked {
  position: relative;
  opacity: 1;
}

.diviceCheckBox input:checked~span.unchecked {
  position: absolute;
  opacity: 0;
}

.pageCard table tr th {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border: none;
  line-height: normal;
  vertical-align: middle;
}

.pageCard table tr td {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  vertical-align: middle;
}

.temStatus {
  max-width: 56px;
  margin: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.temStatus.danger {
  background: rgba(255, 94, 94, 0.15);
  color: #ff5e5e;
}

td.seprator {
  padding: 16px 0 0px 0;
  border-color: #101a36;
}

.pageCard table tr.disable td {
  color: #c4c4c4;
}

.pageCard table tr.disable td svg circle {
  fill: #c4c4c4;
}

.cardTable {
  max-height: 340px;
  overflow-x: auto;
}

.map img {
  height: 100%;
  border-radius: 16px;
  max-height: 356px;
  object-fit: cover;
}

/* .tempratureTab .form-select {
  border-radius: 8px;
  border: 2px solid #72FFFF;
  background-color: #013259;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #72FFFF;
  height: 40px;
  padding: 8px 52px 8px 16px;
  width: auto;
  width: 100%;
  outline: none !important;
  box-shadow: none !important;
  background-image: url(../src/assets/images/tempwareImages/selectDropdown.svg);
  background-size: 24px;
  background-position: right 8px center;
} */

.filterActive {
  border-radius: 8px;
  border: 2px solid #72ffff;
  background: #013259;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 40px;
  line-height: normal;
  color: #72ffff;
}

/* .temDetail {
  border-radius: 8px;
  background: #013259;
  height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #fff;
  border: 2px solid #013259;
} */

.temDetail {
  background: transparent;
  height: 40px;
  font-size: 14px;
  /* font-size: 11px; */
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: #c0c8cc;
  border-radius: 8px;
  line-height: 20px;
  border: 1px solid #8a9296;
}

.temDetail svg {
  flex: 0 0 auto;
}

.temDetail.danger {
  border: 2px solid #ff5e5e;
}

.tempratureTab li {
  width: 100%;
}

.tempratureTab .filterSelect {
  border-radius: 8px;
  background: #09283e;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  min-height: 40px;
}

.tempratureTab .form-select {
  border: none;
  font-size: 14px;
  font-style: normal;
  min-height: 40px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #c0c8cc;
  outline: none !important;
  box-shadow: none !important;
  background-image: url(../src/assets/images/tempwareImages/selectIcon.svg);
  background-size: 24px;
  background-position: right 8px center;
  background-color: transparent !important;
  border: none !important;
  padding-left: 40px;
}

.import-file input {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  ;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.filterSelect svg {
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.graphCard {
  border-radius: 16px;
  background: #fff;
}

.graphFilter {
  border-radius: 100px;
  border: 1px solid #8a9296;
  overflow: hidden;
}

.graphFilter li {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #dee3e5;
  height: 40px;
  display: flex;
  cursor: pointer;
  align-items: center;
  border: 1px solid #8a9296;
  width: 50%;
  flex: 0 0 auto;
  border-top: 0;
  min-width: 104px;
  border-bottom: 0;
}

.graphFilter li svg {
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  display: none;
}

.graphFilter li.active svg {
  display: block;
}

.graphFilter li.active {
  background: #603768;
}

/* .graphFilter li.text-dark {
  color: #000 !important;
} */
/* 
.graphFilter li.active {
  background: #FFD233; 
}*/

.setting {
  border-radius: 8px;
  background: #eee;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.btn.bnt-info,
button.btn.bnt-info:active,
button.btn.bnt-info:hover,
button.btn.bnt-info:focus {
  border-radius: 8px;
  background: #8ecff2;
  border: none !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  min-height: 40px;
  color: #003548;
  height: 40px;
}

.graphTable table tr th,
.graphTable table tr td {
  color: #dee3e5;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border: none;
  line-height: normal;
  width: 10%;
}

.graphTable table tr td {
  font-weight: 400;
  padding: 8px;
}

.graphTable table {
  border-collapse: separate;
  border-spacing: 0px 4px;
}

.graphTable table tr td.bgdanger {
  background: #93000a;
}

.graphTable {
  max-height: 370px;
  overflow: auto;
}

.pageTitle h1 {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

button.btn.btn-primary {
  border-radius: 8px;
  background: #00d7ff;
  padding: 8px 16px 8px 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #101a36;
}

/* .pageSearch {
  border-radius: 8px;
  border: 2px solid #00D7FF;
  background: #101A36;
  height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 8px 10px;
  color: #00D7FF;
} */

.pageSearch input {
  border-radius: 8px;
  border: 2px solid #00d7ff;
  background: #101a36;
  outline: none;
  box-shadow: none;
  height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #00d7ff;
  padding: 8px 10px 8px 43px;
  min-width: 280px;
}

.searchIcon {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.pageSearch input::placeholder {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #00d7ff;
}

.filter.tempratureTab span {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
}

/* .filter.tempratureTab .form-select {
  border-radius: 8px;
  border: 2px solid #fff;
  background-color: #101A36;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
  height: 40px;
  padding: 8px 52px 8px 16px;
  width: auto;
  width: 100%;
  outline: none !important;
  box-shadow: none !important;
  background-image: url(../src/assets/images/tempwareImages/selectDropdownWhite.svg);
  background-size: 24px;
  background-position: right 8px center;
} */

.reportTitle h3 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
  color: #dee3e5;
}

.reportTitle h4 {
  color: #e8b5ef;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.reportTitle h4 .iconactive {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reportData h4 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
  color: #c0c8cc;
}

.reportData {
  padding: 10px 0;
}

.reportData h4.detailValue {
  min-width: 63px;
  color: #fff;
}

.overviewBtn .btn.btn-transparent {
  width: 100%;
  padding: 10px 24px;
  color: #8ecff2;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  min-height: 40px;
  line-height: 20px;
  letter-spacing: 0.1px;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  gap: 8px;
}

.overviewMap img {
  max-height: inherit;
}

.overviewBtn .btn.btn-outline-info {
  width: 100%;
  padding: 10px 24px;
  border-radius: 100px;
  border: 1px solid #8a9296;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  min-height: 40px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #8ecff2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  gap: 8px;
}

.reportTitle h4.yelloColor {
  color: #ffb4ab;
}

.reportTitle h4.yelloColor svg circle {
  fill: #ffb4ab;
}

/*=== device overview new page ===*/
.headerFilter select {
  border-radius: 8px;
  border: 1px solid #8a9296 !important;
  background-color: transparent;
  color: #c0c8cc;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  outline: none !important;
  box-shadow: none !important;
  min-height: 40px;
  letter-spacing: 0.1px;
  background-image: url(../src/assets/images/tempwareImages/selectIcon.svg);
  background-size: 24px;
  background-position: right 8px center;
  background-color: transparent !important;
}

.reportTitle h5 {
  color: #c0c8cc;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.reportTitle h6 {
  color: #c0c8cc;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.25px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dee3e5;
  transition: 0.4s;
  box-shadow: none !important;
  border: 2px solid #70787c !important;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 9px;
  bottom: 0;
  background-color: #70787C;
  transition: 0.4s;
  top: 0;
  margin: auto;
  right: auto;
}

input:checked+.slider {
  background-color: #8ecff2 !important;
}

/* input:checked:focus + .slider {
  box-shadow: 0 0 1px #8ecff2;
} */

input:checked+.slider:before {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  left: auto;
  right: 9px;
  transition: 0.4s;
  background-color: #003548;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.mapConfigModal .ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
  background: #1b2122;
}

/*==== light view ===*/
.light {
  background-color: #f6fafe;
}

.light .MenuTitle h3 {
  color: #40484c;
}

.light .sidebarNewNavMenu li a {
  color: #40484c;
}

.light .sidebarNewNavMenu li a svg path {
  fill: #40484c;
}

.light .divider {
  background: #c0c8cc;
}

.light .sidebarNewNavMenu li.active {
  background-color: #fdd6ff;
}

.light .sidebarNewNavMenu li.active a {
  color: #300a39;
}

.light .sidebarNewNavMenu li.active a svg path {
  fill: #300a39;
}

.light .DashboardSearch input {
  background-color: #e3e9ea;
  color: #40484c;
}

.light .slider {
  background-color: #e3e9ea;
}

.light .DashboardSearch input::placeholder {
  color: #40484c;
}

.light .DashboardSearch svg path {
  fill: #40484c;
}

.light .pageCard {
  background: #eff5f6;
}

.light .cardHeading {
  color: #171d1e;
}

.light .deviceCard {
  border-radius: 8px;
  background: #f5fafb;
}

.light .diviceCheckBox svg path {
  fill: #196584;
}

.light .deviceCard h4 {
  color: #171d1e;
}

.light .deviceCard p {
  color: #40484c;
}

.light .diviceIndicator svg path {
  fill: #ba1a1a;
}

.light .diviceIndicator.green svg path {
  fill: #196584;
}

input:checked+.slider {
  background-color: #8ecff2 !important;
  border: 2px solid #8ecff2 !important;
}

input:focus+.slider {
  box-shadow: 0 0 1px #8ecff2;
  border: 2px solid #8ecff2;
}

.light .tempratureTab .filterSelect {
  background-color: #eff5f6;
}

.light .tempratureTab .form-select {
  color: #40484c;
  background-image: url(../src/assets/images/tempwareImages/lisghtselectIcon.svg);
}

.light .filterSelect svg path {
  fill: #196584;
}

.light .temDetail {
  color: #40484c;
  border: 1px solid #70787c;
}

.light .graphFilter li.active {
  background: #fdd6ff;
}

.light .graphFilter li {
  color: #171d1e;
  border: 1px solid #70787c;
  border-bottom: 0;
  border-top: 0;
}

.light .graphFilter li.active svg path {
  fill: #300a39;
}

.light .graphFilter {
  border: 1px solid #70787c;
}

.light button.btn.bnt-info,
.light button.btn.bnt-info:active,
.light button.btn.bnt-info:hover,
.light button.btn.bnt-info:focus {
  background: #196584;
  color: #fff;
}

.light button.btn.bnt-info svg path,
.light button.btn.bnt-info:active svg path,
.light button.btn.bnt-info:hover svg path,
.light button.btn.bnt-info:focus svg path {
  fill: #fff;
}

.light .temDetail svg path {
  fill: #196584;
}

.light .temDetail.violations svg path {
  fill: #ba1a1a;
}

.light .graphTable table tr th,
.light .graphTable table tr td {
  color: #171d1e;
}

.light .graphTable table tr td.bgdanger {
  background: #ffdad6;
}

.light .headerFilter select {
  border: 1px solid #70787c;
  color: #40484c;
  background-image: url(../src/assets/images/tempwareImages/lisghtselectIcon.svg);
}

.light .reportTitle h3 {
  color: #171d1e;
}

.light .alertPage svg path {
  fill: #ba1a1a;
}

.light .alertPage .success svg path {
  fill: #171d1e;
}

.light .reportTitle h4 {
  color: #7a4f82;
}

.light .reportTitle h4 svg circle {
  fill: #7a4f82;
}

.light .reportData h4 {
  color: #40484c;
}

.light .overviewBtn .btn.btn-transparent {
  color: #196584;
}

.light .overviewBtn .btn.btn-outline-info {
  border: 1px solid #70787c;
  color: #196584;
}

.light .overviewBtn .btn.btn-outline-info svg path {
  fill: #196584;
}

.light .reportData.battery svg path {
  fill: #196584;
}

.light .reportData svg path {
  fill: #ba1a1a;
}

.light span.closeModal svg path {
  fill: #171d1e;
}

.mapConfigModal.light .ant-modal-body {
  background: #fff;
}

.mapConfigModal.light h5 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #171d1e;
}

.mapConfigModal.light p {
  color: #40484c;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
}

.light .ModaldeviceInfo {
  color: #101a36;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mapConfigModal.light label {
  color: #171d1e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
}

.mapConfigModal.light input.form-control {
  height: 60px;
  border-radius: 4px;
  border: 2px solid #70787c;
  background-color: #fff;
  outline: none !important;
  box-shadow: none !important;
  padding: 18px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
  color: #40484c;
}

.mapConfigModal.light .form-select {
  height: 60px;
  border-radius: 8px;
  border: 2px solid #70787c;
  background-color: #fff;
  outline: none !important;
  box-shadow: none !important;
  padding: 18px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
  color: #40484c;
}

.mapConfigModal.light span.formToo {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #40484c;
}

.mapConfigModal.light input.form-control::placeholder {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #000;
}

.light .configButton .btn,
.light .configButton .btn:hover,
.light .configButton .btn:active,
.light .configButton .btn:focus {
  padding: 10.5px 24px !important;
  border-radius: 100px !important;
  background: rgba(23, 29, 30, 0.12) !important;
  border: 0px solid #031a40;
  height: auto;
  min-height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #171d1e4f !important;
  outline: none !important;
  box-shadow: none !important;
}

.light .configButton .btn.btn-outline-primary,
.light .configButton .btn.btn-outline-primary:hover,
.light .configButton .btn.btn-outline-primary:active,
.light .configButton .btn.btn-outline-primary:focus {
  background: transparent !important;
  color: #196584 !important;
  opacity: 1 !important;
}

.light .ModaldeviceInfo svg path {
  fill: #171d1e;
}

@media (max-width:1500px) {
  .filter {
    flex-wrap: wrap !important;
  }
}

@media (max-width: 1680px) {
  .temDetail {
    font-size: 10px;
  }

  .graphFilter li {
    font-size: 11px;
    min-width: 92px;
  }
}

@media (max-width: 1199px) {
  .tempratureTab li {
    width: auto;
  }
}

@media (max-width: 1099px) {
  .pageBody {
    max-width: calc(100vw - 0vw);
    min-height: calc(100vh - 72px);
  }

  aside.sidebarNew {
    width: 30vw;
    z-index: 99;
    display: none !important;
    background: #101a36;
  }

  .hamburger {
    display: flex;
  }

  aside.sidebarNew.show {
    display: flex !important;
  }
}

@media (max-width: 620px) {
  aside.sidebarNew {
    width: 50vw;
  }
}

@media (max-width: 430px) {
  .graphTabFil {
    flex-direction: column !important;
    align-items: start !important;
  }

  aside.sidebarNew {
    width: 100vw;
  }

  .OverviewFilter li {
    width: 100%;
  }
}